$grid-gap: rem-calc(70px);
$grid-gap-small: rem-calc(32px);
$teaser-height: 26em;

#article-filter-control {
  scroll-margin: 8rem;
}

.article-filter__container {
  margin: calc($general-block-margin / 2) 0;
  display: flex;
  gap: $general-block-margin;
  flex-wrap: wrap;
}

.article-filter.button {
  color: $red;
  background-color: $light-gray;
  &:hover,
  &.active {
    background-color: $red;
  }

  margin-right: 0;
}

.article-list__controls {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.article-list__filter--toggle {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  order: 2;
  margin-bottom: $general-block-margin;

  @include breakpoint(medium) {
    order: 1;
    margin-bottom: 0;
  }
}

.article-list__search--toggle {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  order: 2;
  margin-bottom: $general-block-margin;

  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}

.navigation--trigger-search {
  .js-foldout-body {
    padding-top: 0;
  }

  svg {
    width: 12px;
    margin-left: 1rem;
  }
}

.article-list__filter--trigger {
  display: flex;
  align-items: center;

  svg {
    width: 8px;
    transform: rotate(-90deg);
    margin-left: 1rem;
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
    transform-origin: 50% 50%;
    will-change: transform;

    .toggled & {
      transform: rotate(90deg);
    }
  }
}

.article-list__filter--content {
  @include breakpoint(small only) {
    .js-foldout-body {
      padding-top: 0;
    }
  }

  &:hover,
  &.article-list__filter--item {
    color: rgba(4, 12, 38, 0.4);
  }
}

.article-list__filter--list {
  @include breakpoint(medium) {
    max-width: 70%;
  }

  li {
    display: inline-block;
    margin-bottom: $general-block-margin;
    margin-right: $general-block-margin;

    &:hover a {
      color: $black !important;
    }

    & a.active {
      color: $primary !important;
    }
  }
}

.article-list__filter--left {
  display: flex;
  flex-direction: column;
}

.article-filter__result-container {
  display: flex;
  margin: $general-block-margin 0;

  &:hover {
    .button {
      opacity: 0.6;
    }
  }
}

.article-filter__current-category {
  display: flex;
  justify-content: center;
  border: 1px solid #d7d7d7;
  border-radius: 16px;
  padding: 8px 16px 6px 18px;
  line-height: 1;
  margin-left: 1rem;

  @include breakpoint(small only) {
    margin-left: 0;
    width: fit-content;
    margin-bottom: $general-block-margin;
  }

  &::selection {
    background: none;
  }

  svg {
    margin-left: 0.75rem;
    width: 16px;
    height: 16px;
  }
}

.article-list--search-form {
  .field-container {
    border: 0;
    //margin: 0.5em 0;
    padding: 0.5em 0.5em 0.5em 1em;

    input {
      background: transparent;
      border: 0;
      color: inherit;
      font: inherit;
      outline: 0;
      background: $primary;

      margin: 0.5em 0;
      padding: 0.5em 0.5em 0.5em 1em;
      width: 100%;
      @include breakpoint(medium) {
        width: 300px;
      }
    }

    #search:hover,
    #search:focus {
      background: $primary;
    }
  }
}

.js-foldout-body--search {
  padding-top: 0;
}

// For Modern Browser with CSS Grid Support
@supports (grid-area: auto) {
  .article__grid {
    margin-top: 0;
    margin-bottom: $general-margin;

    @include breakpoint(medium) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)) 450px;
      grid-auto-rows: calc($grid-gap-small / 4);
      column-gap: $grid-gap;
      row-gap: 0;

      margin-bottom: $general-margin;
      margin-top: $general-margin;
    }

    .article-card {
      margin-right: 0;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }
}

.article__grid {
  .article-card {
    height: fit-content;

    @include breakpoint(medium) {
      border-top: $general-border-black;

      &.article-card--no-border {
        border-top: 0;

        .article-card__wrapper {
          padding-top: 0;
        }
      }
    }

    &:nth-child(-n + 2) {
      border-top: none;
    }

    //&:nth-child(3n+1):nth-last-child(-n+3),
    //&:nth-child(3n+1):nth-last-child(-n+3) ~ .article-card{
    //  border-bottom: none;
    //}
  }
  .article-card__wrapper {
    @include breakpoint(medium) {
      padding-bottom: $general-margin;
      padding-top: $general-margin;
    }
  }
}

.article-card__wrapper {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  //display: grid;
  //grid-template-rows: 1fr 3fr;
  //@include resprop(
  //    "min-height",
  //      ($teaser-height * 0.7, $teaser-height * 0.8, $teaser-height)
  //);
}

// Pagination

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: $general-margin;
  padding-top: $general-margin;
}

.pagination__wrapper {
  display: flex;
  padding: 0 3rem;
}

.next-page {
  padding-left: calc($general-block-margin / 4);
}

.previous-page {
  padding-right: calc($general-block-margin / 5);
}

.pagination-arrow {
  display: block;

  svg {
    width: 2.5rem;
    height: auto;
  }
  svg path {
    fill: $primary;
  }

  &.pagination-arrow--gray {
    svg path {
      fill: $dark-gray;
    }
  }

  &.pagination-arrow--left {
    transform: rotate(180deg) translateY(7px);
  }
}
