.donation-badge {
  position: absolute;
  right: -2rem;
  top: 3rem;
  transition:
    transform 0.3s ease-in-out,
    opacity 0.2s;
  display: block;

  font-size: var(--interact-size);
  font-weight: bold;

  @include breakpoint(medium) {
    right: -4vw;
    top: 12rem;
    transition:
      transform 0.55s,
      opacity 0.2s;
  }

  .scrolled & {
    transform: translateY(-20rem);
    pointer-events: none;
    svg {
      transform: scale(0.3) rotate(-120deg);
    }
  }

  svg {
    transform: scale(0.8) rotate(20deg);
    transition: transform 0.35s;
  }

  svg #Path_140 {
    transform-origin: 50% 50%;
    animation: rotate 8s linear infinite paused;
  }

  &:hover {
    cursor: pointer;
    svg {
      transform: scale(0.9) rotate(20deg);
      #Path_140 {
        animation-play-state: running;
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
