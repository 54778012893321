html {
  font-size: 16px;
  line-height: 1.4;
  @include breakpoint(xLarge) {
    font-size: 18px;
    line-height: 1.5;
  }

  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  background-color: $white;

  &.page--article {
    @extend %theme-body-background;
  }

  &.nav-is-open {
    overflow: hidden;
  }
}

.main-content {
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;

  margin-top: 4rem;

  @include breakpoint(large) {
    margin-top: 9.5rem;
  }
}

// General elements / behavior styling
// ===================================

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
}

.section {
  //position: relative;

  &.section--margin-2x {
    margin-top: 3rem;
    @include breakpoint(medium) {
      margin-top: 6rem;
    }

    @include breakpoint(large) {
      margin-top: 12rem;
    }
  }

  &.section--meta,
  &.section--margin {
    margin-top: 3rem;

    @include breakpoint(medium) {
      margin-top: 4rem;
    }
  }

  &.section--padding {
    padding: $general-margin 0;

    @include breakpoint(medium) {
      padding: $general-margin * 2 0;
    }
  }

  &.section--padding-x2 {
    padding: $general-margin * 2 0;

    @include breakpoint(medium) {
      padding: $general-margin * 3 0;
    }
  }

  &.section--intro {
    background-color: $light-gray;
    padding-bottom: calc($general-margin / 2);
    margin-bottom: $general-margin;
  }

  &.section--border {
    background-color: $yellow-light;
    position: relative;

    &:before,
    &:after {
      content: "";
      background-image: url("static/assets/svg/border-white.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left: -2rem;
      height: 5rem;
      overflow-y: hidden;
      overflow-x: hidden;
      width: 110vw;
      @include breakpoint(small only) {
        width: 200vw;
      }
    }

    &:before {
      top: -2rem;
      transform: rotate(180deg) translateX(-5px);
    }

    &:after {
      bottom: -2rem;
      transform: translateX(-5px);
    }
  }

  &.section--border--gray {
    background-color: $light-gray;
  }
}

.section--background {
  padding: $general-margin 0;

  @include breakpoint(medium) {
    padding: $general-margin * 2 0;
  }
}

.section--gray {
  background-color: $light-gray;
}

.section--yellow {
  background-color: $yellow-light;
}

.subpage-entry--section {
  margin-top: 10rem;

  @include breakpoint(medium) {
    margin-top: 15rem;
  }
}

.align-center--small {
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.align-center--medium {
  @include breakpoint(medium down) {
    justify-content: center;
  }
}

.grid-container--margin {
  @include resprop("margin-bottom", ($general-margin, $general-margin * 1.5));
}
.grid-container--margin-half {
  @include resprop("margin-bottom", ($general-margin * 0.5, $general-margin));
}

.grid-container--margin-x2 {
  @include resprop("margin-bottom", ($general-margin, $general-margin * 3));
}

.show-for-medium-down {
  display: none;
  @include breakpoint(medium down) {
    display: block;
  }
}

.margin-top {
  margin-top: $general-margin;
}

.z10 {
  z-index: 10;
  position: relative;
}

.no-pointer-event {
  pointer-events: none;
  user-select: none;
}

.grid-vh60 {
  @include resprop("min-height", (100%, 60vh, 60vh));
}
.grid-vh40 {
  @include resprop("min-height", (100%, 40vh, 40vh));
}

.grid-100 {
  height: 100%;
}

#djDebug {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

.full--medium {
  @include breakpoint(medium) {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 115%;
  height: 100%;

  .iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    min-height: 100%;
  }
}

.break-spaces {
  white-space: break-spaces;
}
