.card {
  background-color: $light-gray;
  width: 100%;

  @include breakpoint(medium) {
    width: auto;
  }
}

.card__header {
  margin-bottom: $general-margin;
}

.card__content {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.card__address {
  margin-bottom: $general-margin;

  @include breakpoint(medium) {
    flex: 0 1 45%;
    &.card__address--border {
      &:nth-child(odd):not(:last-child) {
        border-right: $general-border-black;
      }
    }
  }
}

.card__footer {
  display: flex;
}
