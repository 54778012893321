$black: #202020;
$white: #ffffff;

// main colors

// COLOR FROM ASSETS PANEL
$color_cfs_Schwarz: rgba(12, 12, 16, 255);
$color_cfs_Rot: rgba(255, 57, 57, 255);
$color_cfs_Rot_lucent: rgba(255, 57, 57, 0.2);
$color_cfs_Blau: rgba(103, 180, 245, 255);
$color_cfs_Blau_Medium: rgba(132, 204, 253, 255);
$color_cfs_Blau_Light: rgba(204, 234, 254, 255);
$color_cfs_Gelb: rgba(245, 188, 77, 255);
$color_cfs_Trkis: rgba(44, 191, 124, 255);
$color_cfs_Grn: rgba(151, 196, 53, 255);
$color_cfs_Grn_Light: #dcefbe;
$color_cfs_Rot: rgba(255, 57, 57, 255);
$color_weiss: rgba(255, 255, 255, 255);
$color_cfs_Gelb_Light: rgba(255, 241, 210, 255);
$color_rubrik_Farbe: rgba(156, 156, 156, 255);
$color_cfs_Gelbmedium: rgba(255, 221, 146, 255);
$color_cfs_Trkis_Light: rgba(181, 240, 211, 255);
$color_assets_15: rgba(255, 57, 57, 255);
$color_assets_16: rgba(12, 12, 16, 255);
$color_cfs_Grn_Light: rgba(220, 239, 190, 255);

$blue-alpha: rgba(204, 234, 254, 0.22);
$blue: $color_cfs_Blau;
$blue-light: rgb(229, 236, 241);
$blue-medium-light: $color_cfs_Blau_Light;

$green-alpha: rgba(220, 239, 190, 0.22);
$green: $color_cfs_Grn;
$green-light: #eaeee1;
$green-medium-light: $color_cfs_Grn_Light;

$turquoise: $color_cfs_Trkis;
$turquoise-light: rgb(227, 245, 236);
$turquoise-medium-light: $color_cfs_Trkis_Light;

$yellow: $color_cfs_Gelb;
$yellow-light: #fdf6ea;
$yellow-medium-light: $color_cfs_Gelb_Light;

$red: $color_cfs_Rot;
$red-light: #faf2f2;
$red-medium-light: #ffebeb;
$red-attention: rgba(255, 235, 236, 255);

$secondary: #120d34;

$light-gray: #f8f8f8;
$medium-gray: #ebebeb;
$dark-gray: #bfbfbf;
$menu-color: $dark-gray;

$primary: $color_cfs_Rot;
$primary-lucent: $color_cfs_Rot_lucent;
$headings-color: $black;

$primary-gray: $light-gray;
$secondary-gray: #a09f9f;

$box-shadow: 0 8px 17px -18px rgba(32, 32, 32, 0.6);
$text-shadow: 0 4px 4px -4px rgba(32, 32, 32, 0.6);

h1,
h2,
h3,
h4,
h5,
h6,
br,
p,
i,
a,
label,
blockquote,
figure,
ol,
input,
pre,
sub,
em,
button,
img,
strong,
li,
ul {
  margin: 0;
  padding: 0;

  &::selection {
    //background: #ebb43a;
    //color: black;
  }
  &.text--yellow,
  &.text--yellow-darken {
    &::selection {
      //background: #ebb43a;
    }
  }
}

%theme-body-background {
  &.theme--green {
    background-color: $green-light;
  }
  &.theme--blue {
    background-color: $blue-light;
  }
  &.theme--yellow {
    background-color: $yellow-light;
  }
  &.theme--red {
    background-color: $red-light;
  }
  &.theme--gray {
    background-color: $light-gray;
  }
  &.theme--turquoise {
    background-color: $turquoise-light;
  }
}

%theme-background-color {
  .theme--green & {
    background-color: $green-light;
  }
  .theme--blue & {
    background-color: $blue-light;
  }
  .theme--yellow & {
    background-color: $yellow-light;
  }
  .theme--red & {
    background-color: $red-light;
  }
  .theme--gray & {
    background-color: $light-gray;
  }
  .theme--turquoise & {
    background-color: $turquoise-light;
  }
}

%theme-background-color-medium {
  .theme--green & {
    background-color: $green-medium-light;
  }
  .theme--blue & {
    background-color: $blue-medium-light;
  }
  .theme--yellow & {
    background-color: $yellow-medium-light;
  }
  .theme--red & {
    background-color: $red-medium-light;
  }
  .theme--turquoise & {
    background-color: $turquoise-medium-light;
  }
}
%theme-background-color-cover {
  .theme--green & {
    background-color: $green;
  }
  .theme--blue & {
    background-color: $blue;
  }
  .theme--yellow & {
    background-color: $yellow;
  }
  .theme--red & {
    background-color: $red;
  }
  .theme--turquoise & {
    background-color: $turquoise;
  }
}

%theme-stroke-color {
  .theme--green & {
    .svg-inner {
      stroke: $green !important;
    }
  }
  .theme--blue & {
    .svg-inner {
      stroke: $blue !important;
    }
  }
  .theme--yellow & {
    .svg-inner {
      stroke: $yellow !important;
    }
  }
  .theme--red & {
    .svg-inner {
      stroke: $red !important;
    }
  }
  .theme--turquoise & {
    .svg-inner {
      stroke: $turquoise !important;
    }
  }
}

%theme-border-svg-light-color {
  .theme--green & {
    background-image: url("static/assets/svg/border-light-green.svg");
  }
  .theme--blue & {
    background-image: url("static/assets/svg/border-light-blue.svg");
  }
  .theme--yellow & {
    background-image: url("static/assets/svg/border-light-yellow.svg");
  }
  .theme--red & {
    background-image: url("static/assets/svg/border-light-red.svg");
  }
  .theme--turquoise & {
    background-image: url("static/assets/svg/border-light-turquoise.svg");
  }
}
