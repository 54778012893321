// Team

.team-teaser {
  display: flex;
  flex-direction: column;
  margin-top: $general-margin;
  @include breakpoint(medium) {
    flex-wrap: wrap;

    &.team-teaser--pain {
      flex-direction: row;
    }
    @include breakpoint(large) {
      justify-content: flex-start;
    }
  }
}

// Author

.author__wrapper {
  display: flex;
  //justify-content: space-between;
  //flex-wrap: wrap;
  flex-direction: column;
}

.author__container {
  display: flex;
  margin-bottom: $general-margin;

  @include breakpoint(medium) {
    .team-teaser--pain & {
      flex: 0 0 50%;
      &:nth-child(odd):not(:last-child) {
        border-right: $general-border;
        padding-right: 2rem;
      }
      &:nth-child(even) {
        padding-left: 2rem;
      }
    }
  }
  .author__image-container {
    margin-right: $general-block-margin;
    height: 100%;

    flex: 0 0 20%;

    @include breakpoint(medium) {
      flex: 0 0 14%;
    }

    &.author__image-container--small {
      width: 4rem;
    }
    img {
      display: block;
      border-radius: 50%;
      height: auto;
      max-width: 100%;
      object-fit: cover;
      font-family: "object-fit: cover";
    }
  }
}

.team-teaser--pain .author__content {
  flex: 0 0 100%;
}
