.accordion--wrapper {
  //margin-bottom: $general-margin;

  .accordion:last-of-type {
    padding-bottom: calc($general-block-margin / 1.5);
    border-bottom: 1px solid $medium-gray;

    &.accordion--substring {
      border: none;
    }
  }

  &:hover,
  &.accordion--active {
    .accordion .accordion--headline p {
      color: rgba(4, 12, 38, 0.4);
    }
  }

  counter-reset: list;
}

.accordion {
  padding-bottom: calc($general-block-margin / 1.5);
  @extend %theme-background-color-medium;

  &:hover p,
  &.toggled p {
    color: $black !important;
  }
}

.js-foldout-wrapper {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition:
    height 0.5s,
    margin 0.5s,
    opacity 0s 0.12s;
  @include breakpoint(medium) {
    transition:
      height 1s cubic-bezier(0.19, 1, 0.22, 1),
      margin 1s cubic-bezier(0.19, 1, 0.22, 1),
      opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
  }

  .toggled & {
    opacity: 1;
  }

  .navigation-list__item--lang & {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.js-foldout-body {
  //padding-bottom: $general-block-margin;
  padding-top: $general-block-margin;

  @include breakpoint(large) {
    p,
    ul,
    ol {
      margin-left: 2.7rem;
    }
  }

  p {
    margin-bottom: 1rem;
    max-width: 90%;

    .accordion--substring & {
      max-width: 100%;
      margin-bottom: $general-block-margin;
      @include breakpoint(medium) {
        max-width: 90%;
      }
    }
  }
}

.accordion--headline {
  border-top: 1px solid $medium-gray;
  padding-top: calc($general-block-margin / 1.5);
  cursor: pointer;

  .accordion--substring & {
    border: none;
  }
  p {
    margin-bottom: 0;
    max-width: 90%;
  }
}

.accordion--headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .slider--subpage & .accordion--headline-inner-wrapper {
    width: 80%;
  }
}

.accordion--header {
  flex-basis: 100%;
}

.accordion--trigger-icon {
  transform-origin: 50% 50%;
  display: block;
  transition: transform 0.35s;

  @include breakpoint(large) {
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;

    &.accordion--trigger-icon--small {
      transition: transform 0.35s;
    }
  }
  &:not(.accordion--trigger-icon--small) {
    @extend %theme-stroke-color;
  }

  &.accordion--trigger-icon--small {
    margin-left: 0.5rem;

    svg {
      width: 1rem;
      height: 0.5rem;
    }
  }

  .toggled &,
  .toggle--collapsed & {
    transform: rotate(180deg);
  }
}
