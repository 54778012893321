// will output the property for increasing breakpoint sizes
// (breakpoints defined in $breakpoints for foundation)
// example usage:
// h1 {
//   @include resprop('font-size', (2.5rem, 3rem));
//   @include resprop('text-align', (start, center));
// }

$z-index-map: (
  "header": 1500,
  "controls": 900,
  "header-trigger": 1600,
  "topic-links": 800,
  "featured-blogs": 50,
  "sources": 0,
);

@function z-index($key) {
  @return map-get($z-index-map, $key);
}

@mixin resprop($property, $values) {
  $i: 1;
  @each $value in $values {
    @include breakpoint(nth($breakpoints, $i)) {
      #{$property}: $value;
    }
    $i: $i + 1;
  }
}

@mixin sectionPadding($property, $values) {
  $i: 1;
  @each $value in $values {
    @include breakpoint(nth($breakpoints, $i)) {
      #{$property}: $value;
    }
    $i: $i + 1;
  }
}
