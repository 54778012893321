.footer {
  background: $light-gray;
  padding: $general-margin * 5 0 $general-margin * 2 0;
  position: relative;
  overflow: hidden;

  &.footer--border {
    &:before {
      content: "";
      background-image: url("static/assets/svg/border-white.svg");
      @extend %theme-border-svg-light-color;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      left: -2rem;
      height: 9rem;
      overflow: hidden;
      width: 110vw;
      @include breakpoint(small only) {
        width: 200vw;
      }
    }

    &:before {
      top: 0;
      transform: rotate(180deg) translateX(-5px);
    }
  }

  &:after {
    content: "";
    background-image: url("static/assets/svg/eyecatcher_footer.svg");
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    height: 40rem;
    width: 22rem;
    right: -10rem;
    bottom: -2rem;
    z-index: 0;

    @include breakpoint(medium) {
      height: 40rem;
      width: 14rem;
      right: -2rem;
    }

    @include breakpoint(large) {
      height: 40rem;
      width: 22rem;
      right: 0;
    }
  }
}

.footer__contact {
  position: relative;
  z-index: 1;
  @include breakpoint(medium) {
    padding-left: $general-margin * 2;
    border-left: $general-border-black;
  }
}

.footer__meta,
.footer__social {
  margin-bottom: $general-margin;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}
