.partner__image {
  width: 370px;
  max-width: 100%;
  aspect-ratio: 1.78/1;

  background: $white no-repeat center center;
  background-size: contain;

  margin-bottom: 1.5rem;

  transition: transform 0.35s;
}

a.partner__container:hover .partner__image {
  transform: scale(1.05);
}
