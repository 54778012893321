.cta__container {
  padding-top: $general-margin;
  margin-bottom: $general-margin;

  @include breakpoint(medium) {
    padding-top: $general-margin;
    margin-bottom: $general-margin * 3;
  }
}

.cta__inner-wrapper {
  padding-bottom: $general-margin;

  @include breakpoint(medium) {
    padding-bottom: 0;
  }
}

.cta__content p {
  font-size: 1.125rem;
}

.cta {
  position: relative;
  padding: $general-margin * 2 0;

  &.cta--first {
    background-color: $primary;
    color: $white;
    padding-top: $general-margin * 3;

    @include breakpoint(small only) {
      padding-bottom: 0;
    }
    &:before {
      content: "";
      background-image: url("static/assets/svg/custom_border.svg");
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: -2rem;
      left: -2rem;
      height: 5rem;
      overflow-y: hidden;
      overflow-x: hidden;
      transform: rotate(180deg) translateX(-5px);
      width: 110vw;

      @include breakpoint(small only) {
        width: 200vw;
      }
    }
  }

  &.cta--second {
    color: $primary;
    background-color: $primary-lucent;
  }
}

.cta__headline {
}

.cta__button {
  @include breakpoint(large) {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}
.cta__image-container {
  position: absolute;
  bottom: -1px;
  height: 100%;
  display: flex;
  align-items: flex-end;

  @include breakpoint(small only) {
    position: relative;
  }

  @include breakpoint(medium) {
    width: 45vw;
    left: 0;
  }

  @include breakpoint(large) {
    width: 35vw;
    left: 5vw;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    //object-fit: cover;
    //font-family: "object-fit: cover";
  }
}
