.articles {
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: $general-block-margin;
  }
  .form-group {
    flex: 0 0 100%;
    @include breakpoint(medium) {
      flex: 0 0 calc(50% - $general-block-margin);
    }
    margin-bottom: 0;
    &--submit {
      margin-top: $general-block-margin;
      flex-basis: 100%;
    }
  }
  &.layout--_columned .form-group {
    flex: 0 0 100%;
  }
  .form-fieldset {
    margin: 0;
  }
  .checkbox-standalone {
    flex-flow: row;
    align-items: flex-start;
  }
}
