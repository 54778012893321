.plugin__richtext,
.plugin__flipbook {
  sub {
    font-size: 60%;
  }

  ul,
  ol {
    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  a:not(.no-decoration, .download__card) {
    color: $primary;
    font-weight: bold;
    //@extend %topic-theme-font-color;
  }

  h2,
  h3:not(.article-card__content-title),
  h4 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $general-block-margin;
    margin-bottom: $general-block-margin;

    @include breakpoint(medium) {
      margin-top: $general-block-margin * 2;
    }

    &[style="text-align:center"] {
      max-width: 80%;
      margin: $general-block-margin * 2 auto;
      line-height: 1.4;

      @include breakpoint(medium) {
        margin: $general-block-margin * 2.5 auto;
      }
    }
  }

  ol {
    list-style: none;
    counter-reset: list;
    margin-left: 0;
    padding-left: 0;

    li {
      position: relative;
      padding: 0.6rem 0 0.7rem 3rem;
      margin-bottom: 0 !important;

      &::before {
        position: absolute;
        left: 0;
        counter-increment: list;
        content: counter(list, decimal-leading-zero);
        @extend .text--bold;
        @extend .text--red;
        margin-right: 1.5rem;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      position: relative;
      padding: 0.6rem 0 0.7rem 2rem;
      margin-bottom: 0 !important;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 1rem;
        border: 0.15rem solid $primary;
        border-radius: 50%;
        height: rem-calc(10px);
        width: rem-calc(10px);
        margin-right: 1.5rem;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

.plugin__definition_list table {
  padding: rem-calc(15) !important;
  background-color: #f0f8e3;

  margin-bottom: 0;

  td {
    vertical-align: top;
    padding: 0 rem-calc(10) rem-calc(10) 0;

    &.plugin__definition_list__definition {
      width: 100%;
    }
    @include breakpoint(medium down) {
      display: block;
      &:first-child {
        padding-bottom: 0;
        font-weight: bold;
      }
    }
  }

  a {
    color: #ff3939;
    font-weight: bold;
    @include breakpoint(small only) {
      word-break: break-all;
    }
  }
  p {
    margin-bottom: 0px;
  }
}

.plugin__attention-box {
  background-color: $red-medium-light;
  padding: 1.5rem 2.25rem;
  margin-bottom: $general-margin;

  *:last-of-type {
    margin-bottom: 0;
  }
  .theme--red & {
    background-color: $red;
    color: $white;
    a {
      color: inherit !important;
      text-decoration: underline;
    }
  }
}

.articles.layout--_standard,
.page--regions {
  .plugin__richtext,
  .plugin__definition_list,
  .image__wrapper {
    @extend .small-11;
    @extend .medium-8;
    @extend .large-7;
  }
  .header-cell {
    @extend .small-11;
    @extend .medium-10;
  }
}
