.region__wrapper {
  padding: $general-margin * 2 0;

  &.region__wrapper--gray {
    background-color: $light-gray;
  }
}

.region-item {
  margin-bottom: $general-margin;

  &:hover {
    .region-item__image-container figure {
      transform: scale(1.05);
    }

    .region-item__container {
      box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.1);
    }

    //.article-card__content-title {
    //  color: $primary;
    //}
  }
}

.region-item__link {
  display: flex;
  justify-content: flex-end;
  margin-top: $general-block-margin;
}

.region-item--second {
  @include breakpoint(medium) {
    margin-top: $general-margin * 3;
  }
}

.region-item__container {
  transition: box-shadow 0.35s;
}

.region-item__image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: rem-calc(300px);

  @include breakpoint(small only) {
    box-shadow: 8px 0 16px 0 rgba(0, 0, 0, 0.1);
  }

  @include breakpoint(medium) {
    height: rem-calc(600px);
  }

  figure {
    transition: transform 0.35s;
  }
}

.region-item__content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: $general-block-margin;
  background-color: $white;
}

.section--region {
  position: relative;
  &:before {
    content: "";
    background-image: url("static/assets/svg/custom_border.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -2rem;
    left: -2rem;
    height: 5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    transform: rotate(180deg) translateX(-5px);
    width: 110vw;

    @include breakpoint(small only) {
      width: 200vw;
    }
  }
  &:after {
    content: "";
    background-image: url("static/assets/svg/custom_border.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -2rem;
    left: -2rem;
    height: 5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 110vw;

    @include breakpoint(small only) {
      width: 200vw;
    }
  }
}

.region-map {
  *[data-toggle] {
    fill: $yellow;
    stroke: $black;
    stroke-width: 2px;
  }
  .lake > path {
    fill: $blue;
  }
}

.region-map.linked *[data-toggle] {
  cursor: pointer;
  &.hover,
  &:hover {
    fill: $red;
  }
}

.regions__overview__menu {
  list-style: none;
  padding-left: 0;
}
.regions__overview__list-item {
}
.regions__overview__link {
  padding: 1rem 0;

  border-top: 2px solid $medium-gray;
  justify-content: space-between;
  font-size: 1.33rem;

  svg {
    height: 1.33rem;
    width: auto;
  }
}
