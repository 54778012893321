:root {
  --interact-size: clamp(0.875rem, 3vw, 1.125rem);
}

$bezier: cubic-bezier(0.215, 0.61, 0.355, 1);
$time: 300ms;
@mixin animated($time: 300ms, $fillmode: both, $count: 1) {
  animation-duration: $time;
  animation-fill-mode: $fillmode;
  animation-iteration-count: $count;
}

$grid-gap: rem-calc(20px);
$grid-gap-small: rem-calc(16px);

$transition-slow: all 0.4s ease-in-out;
$transition: all 0.25s ease-in-out;
$transition-fast: all 0.15s ease-in-out;

$rect-height: 8px;

$general-border: 0.1rem solid $medium-gray;
$general-border-black: 0.1rem solid $dark-gray;
$general-border-transparent: 0.15rem solid transparent;
$box-shadow:
  8px 8px 17px -18px rgba(32, 32, 32, 0.3),
  -8px 8px 17px -18px rgba(32, 32, 32, 0.3);

// calc(2 * (1.5vw + 1vh - 1vmin))

// Increase when decreasing viewport
// calc(300px - 20vw);
