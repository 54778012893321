.content-header__container {
  display: flex;
  justify-content: flex-start;
  position: relative;
  color: #fff;
  height: 65vh;
  padding: rem-calc(35px) 0;
  max-width: 90rem;
  margin-inline: auto;

  .grid-container {
    max-width: 80rem;
    width: 100%;
  }

  @include breakpoint(large) {
    padding: rem-calc(35px) 0 rem-calc(90px) 0;
  }
}

.content-header__top-title {
  font-size: rem-calc(17px);

  @include breakpoint(medium) {
    font-size: rem-calc(24px);
  }
}

.content-header__image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: transparent
      linear-gradient(11deg, #000000 0%, rgba(255, 255, 255, 0.9) 60%) 0% 0%
      no-repeat padding-box;
    mix-blend-mode: darken;
    user-select: none;
    pointer-events: none;
    opacity: 0.32;
    z-index: 1;
  }

  &:before {
    content: "";
    background-image: url("static/assets/svg/border-white.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -2rem;
    left: -2rem;
    height: 5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 110vw;
    z-index: 2;

    @include breakpoint(small only) {
      width: 200vw;
    }
  }
}

.content-header__image-copyright {
  position: absolute;
  right: 1rem;
  bottom: 2.5rem;
  transform: rotate(-90deg) translateX(100%);
  transform-origin: right;
  @include breakpoint(medium) {
    right: 2rem;
    bottom: 4rem;
    transform: none;
  }
}

.content-header__content-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-content: flex-end;
  height: 100%;
  margin-top: auto;
  z-index: 2;
}

.subheader__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $general-margin;
  border-bottom: $general-border;
  padding-bottom: calc($general-block-margin / 2);
  margin-bottom: $general-block-margin;
  @include breakpoint(medium) {
    margin-top: $general-margin * 2;
    margin-bottom: $general-margin;
  }
}
