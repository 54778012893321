.congress-teaser {
  position: relative;
  margin-bottom: calc($general-margin / 2);
  padding-top: $general-margin;
  display: block;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 0.15rem;
    width: 100%;
    background-color: $medium-gray;
    z-index: 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 0.15rem;
    width: 100%;
    transform: translateX(-100%);
    background-color: $primary;
    transition: transform 0.25s ease-in-out;
    z-index: 1;
  }

  &:hover {
    .congress-teaser__title {
      color: $red;
    }

    &:before {
      transform: translateX(0%);
    }
  }

  &__image {
    text-align: center;
    & img {
      margin-bottom: $general-block-margin;
    }
  }

  > span {
    font-size: var(--interact-size);
  }
}

.congress-teaser--is-featured {
  background-color: $primary;
  color: $white;
  position: relative;
  margin-bottom: calc($general-margin / 2);
  padding: $general-margin $general-block-margin;
  display: block;
  overflow: hidden;
  transition: box-shadow 0.35s;
  box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.2);

  &:hover {
    box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.5);
  }
}
