.factsheets__slider {
  position: relative;
  margin-bottom: $general-margin * 1;

  @include breakpoint(medium) {
    margin-bottom: $general-margin * 2;
  }

  .media-slider__go-wrapper {
    bottom: -2rem;

    @include breakpoint(medium) {
      bottom: -4rem;
    }
  }
}

.factsheets__header-wrapper {
  margin-bottom: $general-margin;
  @include breakpoint(medium) {
    margin-bottom: $general-margin * 2;
  }
}

.factsheets__slides {
  overflow: hidden;
  position: relative;
}

.factsheets__slides-container {
  display: flex;
}

.factsheet {
  padding-right: $general-margin * 1.5;
  margin-bottom: $general-margin;
}

.factsheet__wrapper {
  display: block;

  &:hover {
    .factsheet__title {
      color: $primary;
    }

    .factsheet-divider {
      width: 2.5rem;
      background-color: $primary;
    }

    .factsheet__download {
      color: $primary;
    }
  }
}

.factsheet-divider {
  display: block;
  height: 0.2rem;
  width: 1.5rem;
  background-color: $black;
  margin-bottom: $general-block-margin;

  transition: width 0.35s;
}

.factsheet__download,
.factsheet__title {
  transition: color 0.35s;
}

// Downloads

.downloads__wrapper {
  display: flex;
  flex-direction: column;
}

.download__card {
  background-color: $white;
  box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.2);
  margin-bottom: $general-margin;
  transition: box-shadow 0.35s;
  display: block;
  max-width: 100%;

  @extend %theme-background-color-cover;

  .page--theme & {
    color: $white !important;
  }

  &:hover {
    box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.5);
  }
}

.download__container {
  display: flex;
  padding: 2rem 3rem;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  &:hover {
    .theme--gray & {
      .article-card__content-title,
      span {
        color: $red;
      }
    }
  }
}

.download__container--image {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: calc(100% - 4rem);

  .download__content {
    max-width: 100%;
  }

  @include breakpoint(medium) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .download__content {
      flex: 1 0 50%;
    }

    .download__image {
      flex: 1 0 calc(50% - 2rem);
    }
  }
}

.download__content {
  width: max-content;
  max-width: calc(100% - 4rem);
}

.download__icon {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .page--theme & {
    svg .svg-inner {
      fill: $white;
    }
  }
}

.download__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  max-width: 100%;

  .article-card__content-title,
  span {
    .theme--gray & {
      color: $black;
    }
  }

  .download__card {
    flex: 0 1 calc(50% - 2rem);
  }
}
