.in-page-nav {
  margin-bottom: 2.5rem;
  --columns: 1;
  display: grid;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
  grid-gap: 2rem;

  @include breakpoint(medium) {
    --columns: 3;
  }
  // @include breakpoint(large) {
  //   --columns: 3;
  // }
}
.in-page-nav-backlink {
  margin-bottom: 2rem;
}

.in-page-nav__link {
  font-weight: 600;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  padding: 1.5rem;

  border-radius: 0.5rem;
  background: $light-gray;
  box-shadow: 0px 0px 15px $dark-gray;

  svg {
    transform: rotate(180deg);
  }

  &:hover,
  &:focus-visible,
  &.is-active {
    color: $red;
    box-shadow: 0px 0px 15px $secondary-gray;
  }
  &.is-active {
    svg {
      display: none;
    }
  }
}
