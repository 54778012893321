// Media Slider

.media-slider__container {
  margin-top: calc($general-margin / 2);

  @include breakpoint(medium) {
    margin-top: $general-margin;
  }
}

.media-slider__slide {
  position: relative;
}

.media-slider__slides {
  //max-width: 1000px;
  overflow: hidden;
  position: relative;
}

.media-slider__slider {
  display: flex;
}

.media-slider__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  cursor: pointer;
}

.media-slider__image-wrapper {
  position: relative;

  .image__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.75rem 1.5rem 0.75rem 0;
    background: $white;
    max-width: 80%;

    @include breakpoint(medium) {
      left: 1.25rem;
    }
  }
}

.slider--wrapper {
  margin-top: $general-block-margin;
}

.slider__inner-wrapper {
  display: flex;
}

.slider__content-wrapper {
  position: relative;
  min-height: 4rem;
  @include breakpoint(large) {
    max-width: 60%;
    min-height: 0;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &[data-current] {
      opacity: 1;
    }
  }
}

// Progress

.slider__progress-bar {
  position: relative;
  max-width: 100%;
}

.progress-bar__container,
.progress-bar__bar {
  width: 100%;
  height: 0.3rem;

  @include breakpoint(small only) {
    width: calc(100% - 5vw);
  }
}

.progress-bar__container {
  background-color: $primary-lucent;
  margin-bottom: 2rem;

  &:not(.progress-maxed) {
    overflow: hidden;
    // this fixes the overflow:hidden in Chrome
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  }
}
.progress-bar__bar {
  position: absolute;
  background-color: $primary;
  left: 0;
  top: 0;
  transition: transform 0.35s cubic-bezier(0, 0, 0.175, 1) 0s;
}

.media-slider__thumbs {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);

  &.media-slider__thumbs--facts {
    left: 0;
    position: relative;
    transform: none;
    bottom: auto;
    justify-content: flex-start;

    a[data-thumb] {
      &::before {
        background-color: $white;
      }
    }
  }

  @include breakpoint(medium) {
    bottom: -4rem;
  }

  a[data-thumb] {
    position: relative;
    height: 30px;
    width: 30px;
    margin: 0;
    padding: 0;
    background: none;
    border: 0 none;
    outline: none;
    cursor: pointer;

    &::before {
      background-color: $primary;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 8px;
      width: 8px;
      margin-top: -4px;
      margin-left: -4px;
      transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border-radius: 50%;
      opacity: 0.7;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: scale(0);
      transition:
        transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border: 3px solid $primary;
      opacity: 0;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
    }

    &[data-current] {
      opacity: 1;
      position: relative;
      transition: all ease-in-out 200ms;

      &::after {
        transform: scale(0.8);
        opacity: 1;
      }

      &::before {
        background: $primary;
        opacity: 1;
      }
    }
  }
}

.media-slider__wrapper {
  position: relative;
}

.media-slider__go-wrapper {
  position: absolute;
  width: 3rem;
  height: 3rem;

  .media-slider__slide--moodboard & {
    bottom: -4rem;
  }

  .media-slider__slide & {
    display: none;

    @include breakpoint(medium) {
      display: block;
    }
  }

  &.media-slider__go-wrapper--left {
    position: absolute;
    right: 3.5rem;
  }

  &.media-slider__go-wrapper--right {
    position: absolute;
    right: 0;
  }
}

.media-slider__go {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  filter: grayscale(1) brightness(220%);
  transition: filter 0.35s;
  &:after {
    position: absolute;
    content: url("static/assets/icons/arrow-left.svg");
  }

  &:before {
    content: "";
    position: absolute;
    transition: all 200ms ease-in-out;
  }

  &[data-go="-1"] {
    &:after {
    }
  }

  &[data-go="1"] {
    &:after {
      content: url("static/assets/icons/arrow-right.svg");
    }
  }

  &:hover {
    filter: grayscale(0) brightness(100%);
  }
}
