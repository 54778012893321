.autosize {
  --iframe-height: 900px;
  --iframe-width: 100vw;

  @include breakpoint(medium) {
    --iframe-height: 800px;
  }

  max-width: 100%;
  border-style: none;

  height: var(--iframe-height);
  width: var(--iframe-width);
  overflow: scroll;
}
