// Nav configs
$sticky-header-height: rem-calc(72px);
$sticky-header-height-large: 5rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;

$nav-toggle-transition: 0.25s ease-in-out;

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  user-select: none;
  top: 0;
  left: 0;

  z-index: 900;
}

.navigation {
  background-color: $white;
  transition: $transition-slow;
  z-index: z-index("header");
  position: relative;

  .page--article & {
    background: none;
  }
}

.navigation__wrapper {
  position: relative;
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}

.navigation__inner-wrapper {
  @include breakpoint(medium down) {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    padding: 1rem 2rem;
    align-items: center;
  }

  @include breakpoint(small only) {
    padding: 0.75rem 1.7rem;
  }
}

.mobile-overlay__menu-wrapper {
  position: relative;
  //display: none;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  height: 0;
  overflow: hidden;
  @include breakpoint(large) {
    display: none;
  }
}

.mobile-overlay__menu {
  position: fixed;
  background-color: $white;
  width: 100%;
  right: 0;
  top: 3.55rem;
  padding: 0;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: scroll;
  display: none;
  .nav-is-open & {
    height: 100%;
    display: block;
  }
}

.mobile-overlay__slide {
  height: 100%;
}

.mobile-overlay__slide--bottom {
  transform: translateX(-120%);
  transition: transform 0.35s;

  &.open {
    transform: translateX(0);
  }
}

.mobile-overlay__slide--top {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  z-index: 1000;
  //background-color: $white;
  transform: translateX(100%);
  transition: transform 0.35s;
  &.open {
    transform: translateX(0);
  }
}

$header-size: rem-calc(80px);

.sticky-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.4s ease;
  @extend %theme-background-color;

  box-shadow: $box-shadow;

  .scrolled & {
    @include breakpoint(large) {
      // eslint-disable-next-line
      transform: translateY(-$header-size);
    }

    .page--article & {
      background: #fff;
    }
  }
}

.navigation__container {
  position: relative;
  display: flex;
  flex-direction: column;

  border-bottom: 0.1rem solid transparent;

  [data-open-menu="true"] & {
    border-color: $medium-gray;
  }

  &.navigation__container--mobile {
    display: none;
    @include breakpoint(medium down) {
      display: block;
    }

    .nav-is-open & {
      border-bottom: 1px solid $medium-gray;
      z-index: 1200;
    }
  }
}

.navigation__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: $general-border;
  margin-top: 1rem;
  z-index: 1001;

  .page--theme & {
    border-bottom: $general-border-black;
  }

  .scrolled & {
    border-bottom: $general-border-transparent;
  }
}

.navigation__logo {
  display: block;
  max-width: 200px;
  pointer-events: all;
  transition: transform 0.35s;
  opacity: 1;
  z-index: 1001;

  @include breakpoint(large) {
    .scrolled & {
      transform: translateY(rem-calc(80px));
    }

    &.navigation__logo--hide {
      transform: translateY(0);
    }
  }
}

.navigation__logo-item--image {
  width: 100%;
  height: rem-calc(60px);
  transform: translateY(-0.7rem);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  .scrolled & {
    background-image: url("static/assets/svg/cfs_logo_fr_small.svg") !important;
  }
}

.navigation__logo-item--mobile {
  img {
    width: 140px;
    display: block;
  }
}

.navigation__top--left {
  flex: 0 0 30%;
}

.navigation__top--right {
  flex: 0 0 70%;
}

.navigation-list__item--lang {
  display: flex;
  .navigation-link.navigation-link--accordion {
    padding-left: 2rem;
    padding-right: 0;
  }
}

.navigation-meta--mobile .navigation-link--accordion {
  padding-left: 0;
}

.navigation-meta--mobile .dropdown--children {
  position: unset;
  box-shadow: none;
}

.navigation-main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  z-index: 10;
  transition: position 0.35s;

  &.navigation-main--flex {
    justify-content: space-between;
  }

  .scrolled & {
    //margin-bottom: 0;
  }

  &.navigation-main--mobile {
    justify-content: flex-start;
    margin-top: $general-margin;
    padding: 0 2rem;
  }
}

.navigation-list.navigation-list--main {
  position: unset;
  width: 100%;
  justify-content: space-between;
  @include breakpoint(medium down) {
    flex-direction: column;
  }
  .scrolled & {
    justify-content: flex-end;
  }
}

.navigation-list {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  transition: position 0.35s;

  &.navigation-list--lang {
    justify-content: flex-start;
  }

  &.navigation-list--column {
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .navigation-main--mobile & {
    width: 100%;
  }
  &:hover {
    .navigation-link {
      opacity: 0.4;
    }
  }
}

.navigation-list__item {
  break-inside: avoid;
}

.navigation-list__item--mobile {
  border-bottom: $general-border;
}

.navigation-link {
  display: block;
  cursor: pointer;
  padding: 1.25rem 0 0.2rem 0;

  &.deactivate {
    opacity: 0.4;
  }

  .navigation-main--fr &,
  .scrolled & {
    font-size: $body-font-size-medium;
  }

  .toggle--collapsed &,
  .toggled &,
  &.active {
    opacity: 1 !important;
  }

  &.active {
    color: $primary;
  }

  &.text--gray.active {
    color: $black;
  }

  .navigation-meta & {
    padding: 1.25rem 0.5rem;
  }

  .navigation-main--mobile &,
  &.navigation-link--accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 1.25rem 0;
  }

  &.navigation-link--column {
    padding: 0.5rem 0;

    // Avoid flickering when hovering hover invisible gab between elements
    transform: translateY(-1px);

    .mobile-overlay__menu & {
      padding: 1.25rem 0;
    }
  }

  &.navigation-link--small {
    padding: 0.5rem 0;
    transform: translateY(-1px);
  }

  &:hover {
    opacity: 1 !important;
  }
  &.is-active {
    color: $primary !important;
  }
}

.accordion--trigger-icon {
  .navigation-main--mobile & {
    height: 100%;
    padding: 0 0.2rem;

    svg {
      width: auto;
      height: 1rem;
    }
  }
}

.navigation-list__item--main {
  &.active {
    border-bottom: 2px solid $black;

    .navigation-link {
      opacity: 1;
    }
  }
}

.scrolled .navigation-list__item--main {
  margin: 0 1rem;
}

.navigation-link__children {
  --columns: 1;
  display: none;
  width: 100%;
  padding: 1rem 0;
  margin-block: 1rem;

  .nav-is-open & {
    padding: 0 0 10rem 0;
  }
  &:hover {
    .navigation-link {
      opacity: 0.4;
    }
  }

  .active & {
    position: absolute;
    left: 0;
    display: grid;
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    grid-gap: 0 2rem;
  }

  @include breakpoint(large) {
    --columns: 3;
  }
}

.navigation-link__children--mobile {
  flex-direction: column;
  //padding: 0 2rem;
  display: none;

  .mobile-overlay__slide--top.open & {
    display: flex;
  }
}

.navigation-overlay-container {
  position: relative;
}

.navigation-overlay {
  [data-open-menu="true"] & {
    display: block;
  }
  display: none;
  position: absolute;
  // top: calc(140px + 1rem - 6px);
  right: 0;
  left: 0;
  height: 20rem;
  background-color: $white;
  box-shadow: $box-shadow;

  @extend %theme-background-color;
  //border-top: rem-calc(6px) solid $black;
}

.navigation-list__item--overview {
  grid-row: span 9;
  margin-top: 1rem;
  @include breakpoint(large) {
    margin-top: unset;
  }
}

.navigation-overlay__menu {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.navigation-overlay__menu-wrapper {
  position: relative;
  padding: 3rem 3rem;
  flex: 0 0 48%;
  z-index: 12;
  &:after {
    content: "";
    background-image: url("static/assets/svg/custom_border.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: -2rem;
    right: -2rem;
    height: 110%;
    width: 5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    transform: rotate(180deg) translateX(-5px);
  }
}

.navigation-overlay__content {
  position: relative;
  display: flex;
  width: 100%;
  flex: 0 0 52%;
}

.navigation-overlay__image-wrapper {
  width: 100%;
  margin: 0;
  position: absolute;
  height: 100%;
  overflow: hidden;
  right: -1px;
  &.navigation-overlay__image-wrapper--promo {
    z-index: 10;
  }

  .navigation-overlay__image--child {
    width: 0;
  }
}

.navigation-overlay__image {
  transition: width 0.35s;
  background-color: $white;
}

// Navigation Mobile
// =================

$size: 35px;
$default-color: $black;
$hover-color: $black;

.animate {
  transition-property: margin, transform, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.navigation__trigger {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-trigger {
  display: flex;
  align-items: center;
  width: $size;
  height: $size;
  cursor: pointer;
  @extend .animate;
  z-index: 1100;
  pointer-events: all;
}

.menu-trigger__icon {
  display: inline-block;
  position: relative;
  width: $size;
  height: 3px;
  background-color: $black;

  &:before,
  &:after {
    content: "";
    display: block;
    width: $size;
    height: 3px;
    position: absolute;
    background: $black;
    transition-property: margin, transform, opacity;
    transition-duration: 300ms;
  }

  &:before {
    margin-top: -10px;
  }

  &:after {
    margin-top: 10px;
  }
  .nav-is-open &,
  .close & {
    background: none;

    &:before {
      margin-top: 0;
      transform: rotate(45deg);
    }

    &:after {
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}

.navigation-list__item--lang {
  position: relative;
  z-index: 1200;
}

.navigation-meta--mobile {
  padding: 0 2rem 9rem 2rem;
}

.dropdown-list {
  margin: 0;
  padding: 0;
}

.dropdown--item {
  list-style: none;
  position: relative;
}

.dropdown--toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem 1rem 0.45rem;
  border: 0.12rem solid $white;
  cursor: pointer;
}

.dropdown--link-name {
  display: block;
  margin-right: 1rem;
  pointer-events: none;
}

.navigation-list__item--lang .dropdown--children {
  right: 0;
  left: unset;
}

.dropdown--children {
  display: none;
  margin: 0;
  padding: 0.75rem 1rem 0.75rem;
  position: absolute;
  top: 3rem;
  left: -0.5rem;
  background-color: $white;
  width: max-content;
  // min-width: 100%;
  box-shadow: 0 8px 17px -10px rgba(32, 32, 32, 0.6);
  &:hover {
    .dropdown--child {
      opacity: 0.6;
    }
  }

  .toggle--collapsed & {
    display: block;
  }
}

.dropdown--child {
  list-style: none;
  color: $black;

  &:hover {
    opacity: 1 !important;
  }
}

.dropdown--child-name {
  display: flex;
  align-items: center;
  width: 100%;
}

.navigation-list__item--meta {
  position: relative;
  @include breakpoint(medium down) {
    .navigation-list--lang & {
      margin-right: calc($general-block-margin / 2);
    }
  }
}

.navigation-list__item--donation {
  font-weight: $font-bold;
  color: $primary;

  @include breakpoint(medium down) {
    display: none;
  }

  @include breakpoint(large) {
    overflow: hidden;
    max-width: fit-content;
    margin-left: 1rem;
    margin-right: 0;
    padding-left: 0;
    display: none;

    a {
      white-space: nowrap;
    }
    .scrolled & {
      width: 100%;
      max-width: fit-content;
      margin-left: 1rem;
      display: block;
    }
  }
}

.mobile-overlay__donation {
  position: fixed;
  z-index: 1500;
  bottom: -20px;
  left: -10px;
  color: $white;
  width: calc(100% + 10px);
  height: 6rem;
  display: none;
  background-image: url("static/assets/svg/border-red.svg");
  background-size: cover;
  background-repeat: no-repeat;

  .nav-is-open & {
    display: block;
  }
}

.mobile__donation-cta {
  position: absolute;
  text-align: center;
  font-size: 18px;
  font-weight: $font-bold;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  color: $white;
}

.mobile-slide__back-button {
  cursor: pointer;
}
