$font-main: "Open Sans", sans-serif;
$font-bold: 700;
$font-medium: 600;
$font-regular: 400;
$font-light: 300;

$body-font-size-tiny: rem-calc(14px);
$body-font-size-small: rem-calc(15px);
$body-font-size-medium: rem-calc(18px);
$body-font-size: rem-calc(16px);
$body-font-size-large: rem-calc(22px);
$body-font-size-menu: rem-calc(20px);
$body-line-height: 1.6;
$headings-line-height: 1.3;

$h1-font-size: rem-calc(48px);
$h2-font-size: rem-calc(36px);
$h3-font-size: rem-calc(24px);
$h4-font-size: rem-calc(22px);
$h5-font-size: rem-calc(18px);

$font-weight-main: $font-regular;

$general-block-margin: 1.5rem;
$general-margin: rem-calc(40px);
$general-padding: 2rem;
$general-border-style: dashed;

$letter-spacing: 1px;

%body-font {
  font-family: $font-main;
  font-weight: $font-weight-main;
  font-style: normal;
}

%h1-font-size {
  @include resprop(
    "font-size",
    ($h1-font-size * 0.7, $h1-font-size * 0.9, $h1-font-size)
  );

  .articles & {
    @include resprop(
      "font-size",
      ($h1-font-size * 0.6, $h1-font-size * 0.8, $h1-font-size)
    );
  }
}

%h2-font-size {
  @include resprop(
    "font-size",
    ($h2-font-size * 0.7, $h2-font-size * 0.8, $h2-font-size)
  );
}

%h3-font-size {
  @include resprop("font-size", ($h3-font-size * 0.9, $h3-font-size));
  font-weight: $font-regular;
}

%h4-font-size {
  @include resprop(
    "font-size",
    ($h4-font-size * 0.7, $h4-font-size * 0.8, $h4-font-size)
  );
}

%h5-font-size {
  @include resprop("font-size", ($h5-font-size * 0.9, $h5-font-size));
}

%menu-font-styles {
  font-weight: $font-regular;
  font-size: $body-font-size-medium;
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: $general-block-margin;
  font-family: $font-main;
  line-height: $headings-line-height;
}

h1,
.h1 {
  @extend %h1-font-size;

  &.heading--smaller {
    @include resprop(
      "font-size",
      ($h1-font-size * 2, $h1-font-size * 1.2, $h1-font-size * 0.8)
    );
  }
}

h2,
.h2 {
  @extend %h2-font-size;
  line-height: $headings-line-height;
  font-weight: $font-bold;

  a {
    color: $black !important;
  }

  .section--outro & {
    a {
      color: $primary !important;
      font-weight: $font-light;
    }
  }
}

h3,
.h3 {
  @extend %h3-font-size;
}

.home--title {
  margin-top: $general-margin;

  @include breakpoint(xLarge) {
    margin-bottom: $general-margin * 1.5;
  }
}

h4 {
  @extend %h4-font-size;
  margin-bottom: $general-block-margin * 0.6;
}

h5 {
  @extend %h5-font-size;
  margin-bottom: $general-block-margin * 0.6;
  margin-top: 0;
}

// to be extended where it is
%indent-list-items {
  margin-left: 1em;
}

body {
  font-size: $body-font-size;
  line-height: $body-line-height;
  color: $black;
  font-family: $font-main;
}

p,
form,
table {
  margin-bottom: $general-block-margin;
}

ul,
ol {
  margin-bottom: $general-block-margin;
  padding-left: 1rem;
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: $black;
}

strong {
  font-weight: $font-bold;
}

address {
  font-style: normal;
}

.list {
  margin: 0;
  padding: 0;

  &.list--anchors {
    margin-top: $general-margin;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

//.plugin--padding {
//  .page--home & {
//    @include breakpoint(medium) {
//      padding: 0 3rem;
//    }
//  }
//}

.text--white {
  color: $white;
}

.text--gray {
  color: $secondary-gray;

  @include breakpoint(small only) {
    .home-teaser__box & {
      color: darken($secondary-gray, 30%);
    }
  }
}

.text--lightgray {
  color: $light-gray;
}

.text--black {
  color: $black;
}

.text--red {
  color: $red;
}

.text--margin {
  display: block;
  margin-bottom: $general-block-margin;
}
.text--no-margin {
  margin-bottom: 0;
}

.text--oversized {
  font-size: 80px;
}

.text--large {
  font-size: $body-font-size-medium;

  @include breakpoint(medium) {
    font-size: $body-font-size-large;
  }
}
.text--menu {
  font-size: $body-font-size-menu;
}

.text--medium {
  font-size: $body-font-size-medium;
}

.text--small {
  font-size: $body-font-size-small;
}

.text--tiny {
  font-size: $body-font-size-tiny;
}

.text--shadow {
  text-shadow: 0px 0px 0.5em $black;
}

.text--ultra-large {
  @include resprop(
    "font-size",
    (rem-calc(150px) * 0.75, rem-calc(150px) * 0.8, rem-calc(150px))
  );
  line-height: 0;
}

.text--block {
  display: block;
}

.text--margin-top {
  margin-top: $general-block-margin;
}

.text--centered {
  @include breakpoint(medium) {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.text--unterlined {
  text-decoration: underline;
}

.text--bold {
  font-weight: $font-bold;
}

.text--light {
  font-weight: $font-light;
}

.text--semibold {
  font-weight: $font-medium;
}

.text--margin {
  margin-bottom: $general-block-margin;
  display: block;
}

.text--margin-half {
  margin-bottom: calc($general-block-margin / 2);
  display: block;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.text--link {
  &:hover {
    color: $primary;
  }
}

.icon-wrapper {
  display: flex;
  align-content: center;
  align-items: center;

  &.icon-wrapper--left {
    svg {
      margin-right: calc($general-block-margin / 2);
    }
  }
  &.icon-wrapper--right {
    svg {
      margin-left: calc($general-block-margin / 2);
    }
  }
}

.icon--red {
  svg .svg-inner {
    fill: $red !important;
  }

  svg .svg-strokes {
    stroke: $red !important;
  }
}

.icon--white {
  svg .svg-inner {
    fill: $white !important;
  }

  svg .svg-strokes {
    stroke: $white !important;
  }
}

hr {
  .page--article & {
    margin-top: $general-margin;
  }
  margin-bottom: $general-margin;
  border-bottom: $general-border-black;
  border-top: 0;
  border-right: 0;
  border-left: 0;
}

.text--uppercase {
  text-transform: uppercase;
}
