$grid-gap: rem-calc(70px);
$grid-gap-small: rem-calc(32px);
$teaser-height: 26em;

.masonry-grid,
.teasers {
  display: flex;
  width: 100%;
  //margin-bottom: $general-margin;
  flex-wrap: wrap;
}

.masonry-grid > .teaser {
  flex: 1 1 100%;
  position: relative;
  //transition: $general-transitions;
  //background-color: $article-block-color;

  height: fit-content;

  margin-bottom: $grid-gap-small;
  width: 100%;

  &:first-child:not(.card) {
    @include breakpoint(medium) {
      grid-row-start: 2;
    }
  }

  @include breakpoint(medium) {
    margin-right: $grid-gap-small;
    flex: 1 1 40%;
    width: auto;
  }
}

.teasers > .teaser {
  flex: 1 1 100%;
  margin-bottom: $grid-gap;
  margin-right: 0;
}

.teaser__wrapper {
  //padding-top: 10em;
  //padding: $grid-gap-small;

  //margin-top: -2.65rem;
  //z-index: 1000;

  .teasers & {
    height: 100%;
  }
}

.teaser__item {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  @include resprop(
    "min-height",
    ($teaser-height * 0.7, $teaser-height * 0.8, $teaser-height)
  );

  .teasers & {
    height: 100%;
  }

  .teaser--large & {
    @include breakpoint(large) {
      flex-direction: row-reverse;
    }
  }
}

.teaser__content,
.card__container {
  position: relative;
  padding: $grid-gap-small $grid-gap-small * 1.5 $grid-gap-small $grid-gap-small *
    0.75;

  @include breakpoint(medium) {
    padding: $grid-gap-small * 2 $grid-gap-small * 1.5 0.5rem $grid-gap-small;
  }

  z-index: 1001;
}

.teaser__content--title {
  position: relative;
  z-index: 100;
}

// For Modern Browser with CSS Grid Support
@supports (grid-area: auto) {
  .masonry-grid:not(.masonry-grid--grid) {
    @include breakpoint(medium) {
      display: grid;
      grid-gap: $grid-gap;
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
      grid-auto-rows: $grid-gap;
    }

    &.masonry-grid--small {
      grid-gap: $grid-gap-small;
      grid-auto-rows: $grid-gap-small;
    }
    &.masonry-grid--large {
      grid-auto-rows: $grid-gap * 1.5;
    }

    .card {
      margin-right: 0;
      margin-bottom: $general-margin;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }
}
