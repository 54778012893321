$social: "facebook", "twitter", "linkedin";

$icon-size: 40px;
$icon-size-small: 25px;

@mixin social-icons {
  &.social {
    background-repeat: no-repeat;
    transition: all 0.2s ease;

    @each $icon in $social {
      &.#{$icon} {
        background-image: url("static/assets/icons/#{$icon}.svg");
      }
    }

    &:hover {
      opacity: 0.7;
      transition: all 0.2s ease;
    }
  }
}

.icon {
  display: inline-block;
  font-size: 0;
  width: $icon-size;
  height: $icon-size;
  margin: 0 auto;
  background-size: cover;
  background: no-repeat 50% 50%;
  margin-right: calc($general-block-margin / 2);

  &.icon--small {
    width: $icon-size-small;
    height: $icon-size-small;
  }

  &.icon--last {
    margin-right: 0;
  }

  &.black {
    border-radius: 40px;
  }
}

.icon-large {
  width: 50px;
  height: 50px;
}

.social {
  @include social-icons();
}
