$button-medium: 1rem 1.5rem;
$button-small: 0.8rem 2rem;
$button-tiny: 0.4rem 2rem;

.button,
input[type="submit"] {
  display: inline-block;
  color: $black;
  font-weight: 700;
  padding: $button-medium;
  text-align: center;
  transition: $transition-fast;
  font-size: var(--interact-size);

  &.button--cta {
    display: flex;
    align-items: center;
    align-content: center;
    line-height: normal;
    width: fit-content;
    color: $white;
    background-color: $primary;
    cursor: pointer;

    .cta--first & {
      color: $primary;
      background-color: $white;
    }

    &:after {
      content: url("static/assets/svg/arrow-plain-right-white.svg");
      margin-left: 1rem;
      display: block;
      transform: translateY(rem-calc(2px));

      .cta--first & {
        content: url("static/assets/svg/arrow-plain-right-red.svg");
      }
    }
  }
}

.button--filter {
  margin-right: $general-block-margin;
  background-color: $medium-gray;
  border: 1px solid $red;
  transition:
    background-color 0.35s,
    opacity 0.35s;
  span {
    transition:
      opacity 0.1s,
      color 0.35s;
  }

  &:hover,
  &.active {
    background-color: $primary;
    opacity: 1 !important;
    span {
      color: $white;
    }
  }
}

.button--primary {
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: darken($primary, 20%);
  }
}

.button--icon {
  display: flex;
  align-items: center;
  svg {
    margin-left: calc($general-margin / 2);
  }
}
