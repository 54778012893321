.form-group {
  margin-bottom: $general-block-margin;
  display: flex;
  flex-direction: column;

  fieldset,
  input {
    width: 100%;
  }

  &.form-group--white {
    box-shadow: 0px 3px 6px #00000029;
  }
}

input,
fieldset {
  border: none;
}

fieldset {
  &.form-fieldset--right {
    padding-left: 0;
  }
}

.form fieldset > div:has(input[type="radio"]) {
  display: flex;
  flex-flow: row wrap;
  gap: 3rem;
}

.form-fieldset {
  display: flex;
  flex-direction: column;
  padding: 0;

  input[type="checkbox"],
  input[type="radio"] {
    width: auto;
    margin-right: 1rem;
  }
}

.form-tele,
.form-mail,
.form-number {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.form-checkbox {
  padding: 0px;
  margin: 0px;

  label {
    display: flex;
    gap: 1rem;
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
  }

  input {
    display: block;
    flex: 0 0 1rem;
    box-shadow: none !important;
    width: 1rem;
    height: 1rem;
    position: relative;
    z-index: 20;
  }

  span {
    flex: 1 1 auto;
  }
}

.field-help {
  margin-top: calc($general-block-margin / 2);
  margin-left: calc($general-block-margin / 2);
}

input:not([type="radio"]):not([type="checkbox"]) {
  padding: 1rem 2rem;
}
input {
  margin: 0.4rem 0;
  border-radius: 0;
  box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.1);
  background: $white;
  transition:
    border-color 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;
  border: 0.15rem solid transparent;

  &:hover {
    box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.2);
  }

  &:focus,
  &:active {
    border: 0.15rem solid $blue;
    outline: none;
    box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.2);
  }

  & {
    color: $black;
    font-weight: $font-medium;
    font-size: 16px;
    letter-spacing: 1px;
  }

  &::placeholder {
    font-weight: $font-light;
  }
}

input[type="radio"] {
  box-shadow: none;
}

label {
  &:has(input[type="radio"]:focus),
  &:has(input[type="radio"]:active) {
    outline: 0.15rem solid $blue;
  }
  &:has(input[type="checkbox"]:focus),
  &:has(input[type="checkbox"]:active) {
    outline: 0.15rem solid $blue;
  }
}

.form-element--border-right {
  @include breakpoint(medium) {
    border-right: 1px solid rgba(112, 112, 112, 0.33);
    padding-right: 1rem;
  }
}

.form-group__next {
  button {
    cursor: pointer;
    margin: 0 auto;
    display: block;
  }
}

.form-title {
  margin-bottom: $general-margin;
}

//CSS

label *,
label *::before,
label *::after {
  box-sizing: content-box !important;
}

label span {
  line-height: 1.54;
  font-size: 1rem;
  font-family: inherit;
}

textarea,
select {
  position: relative;
  display: block;
  z-index: 1;
  font-family: inherit;
  color: $black;
  font-weight: $font-medium;
  font-size: 16px;
  letter-spacing: 1px;
  width: 100%;
  padding: 1rem 2rem;
  appearance: none;
  background: $white;
  transition:
    border-color 0.1s ease-in-out,
    box-shadow 0.1s ease-in-out;
  box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.1);
  border: 0.15rem solid transparent;
  outline: 0;
  margin: 0.4rem 0;

  &:hover {
    box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.2);
  }

  &::placeholder {
    font-weight: $font-light;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 13px 8px -12px rgba(32, 32, 32, 0.2);

    border: 0.15rem solid $blue;
  }
}

select {
  width: 100%;
}

.form-error {
  scroll-margin: 30vh;
  color: $color_cfs_Rot;
  font-weight: bold;
}

.error {
  color: $color_cfs_Rot;
  font-weight: bold;
  margin-bottom: 10px;
}

.success-message {
  scroll-margin: 30vh;
  font-weight: bold;
}

.success-message.success-message--not-bold {
  font-weight: normal;
}

.form-message {
  margin-bottom: $general-block-margin;
  font-weight: bold;
}

form .button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.form a {
  color: $primary;
}

#application-form-wrapper {
  .button:not([type="submit"]) {
    margin-bottom: 2rem;
  }

  h2 {
    margin-top: 3.5rem;
  }

  h3 {
    margin-top: 2.5rem;
  }

  h4,
  h5 {
    margin-top: 1.5rem;
  }
  h2:first-child {
    margin-top: 0 !important;
  }

  @include breakpoint(small only) {
    h2 {
      font-size: 1.5rem;
      margin-top: 2.5rem;
    }

    h3 {
      font-size: 1.25rem;
      margin-top: 1.5rem;
    }

    h4,
    h5 {
      font-size: 1rem;
      margin-top: 1rem;
    }
  }
}

#application-form {
  select,
  input,
  textarea {
    &[required]:focus-visible:invalid {
      border: 2px solid $color_cfs_Rot;
    }
    &[required]:focus-visible:valid {
      border: 2px solid $color_cfs_Trkis;
    }
  }

  /* remove spinners from integer fields */
  input[type="number"] {
    -moz-appearance: textfield;
    // text-align: right;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  label[for="has_customer_number"] {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    font-family: "Open Sans", sans-serif;
    line-height: 1.3;
    margin-top: 2.5rem;
  }

  label[for="parent_1_affiliation"],
  label[for="parent_2_affiliation"] {
    font-family: "Open Sans", sans-serif;
    line-height: 1.3;
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 0.9rem;
    margin-top: 1.5rem;
  }
}

.form legend {
  @extend h4;
  font-weight: bold;
  margin-block: 1em 0;
}

.form .form-separator {
  border-bottom: 1px solid;
}

.errorlist {
  color: $red;
}

.fields-grid,
.form fieldset > div.fields-grid:has(input[type="radio"]) {
  @include breakpoint(medium) {
    display: grid;
    gap: 1rem;
  }
  &.g-1-3 {
    grid-template-columns: 1fr 3fr;
  }
  &.g-3-1 {
    grid-template-columns: 3fr 1fr;
  }
  &.g-1-1 {
    grid-template-columns: 1fr 1fr;
  }
}

.fields-flex,
.form fieldset > div.fields-flex:has(input[type="radio"]) {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  row-gap: 0.3rem;
  @include breakpoint(medium) {
    flex-direction: row;
  }
}

#application-form legend.fieldset-legend {
  font-weight: normal;
  font-size: 1rem;
  margin-block: 0 1rem;
}

.form-group:has(#support_type_justification) {
  margin-top: 1rem;
}

.maxlength-warning {
  font-size: 0.8rem;
  color: $green;
}
