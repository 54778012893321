.navigation__breadcrumbs {
  padding: 1.25rem 0 0.2rem 0;
}

.article__image-container {
  margin-bottom: calc($general-block-margin / 2);
  height: 15rem;

  @include breakpoint(medium) {
    height: 36rem;
  }
}

.article__box {
  @extend %theme-background-color-medium;

  @include breakpoint(medium) {
    padding: $general-margin calc($general-margin / 1.5);
  }

  .text--centered {
    ul li {
      text-align: left;
    }
  }
}

.article--divider {
  padding-bottom: calc($general-margin / 1.5);
  border-bottom: $general-border-black;
}
.article--divider-top {
  padding-top: calc($general-margin / 1.5);
  border-top: $general-border-black;
}

.articles.layout--_columned {
  .plugin__richtext,
  .plugin__definition_list,
  .download__wrapper,
  .image__wrapper {
    @extend .small-11;
    @extend .medium-12;
  }
  .header-cell {
    @extend .small-11;
    @extend .medium-10;
  }

  @include breakpoint(medium) {
    .left-column {
      padding-right: 1rem;
    }
    .right-column {
      padding-left: 1rem;
    }
  }
}
