@import "foundation-sites/scss/util/util";

$global-width: rem-calc(1280);

@import "foundation-sites/scss/foundation";

$global-flexbox: true;

$padding-gab-mobile: rem-calc(20px);

$breakpoints: (
  small: 0,
  medium: 750px,
  large: 1280px,
  xLarge: 2000px,
);

$breakpoint-classes: (small medium large);

$grid-column-gutter: (
  small: 30px,
  medium: 30px,
);

$grid-margin-gutters: (
  small: 20px,
  medium: 0,
);

$grid-padding-gutters: (
  small: 10px,
  medium: 40px,
);

@include foundation-xy-grid-classes;
@include foundation-responsive-embed;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-text-alignment;

.medium-align-left {
  @include breakpoint(medium up) {
    justify-content: flex-start;
  }
}

.large-align-left {
  @include breakpoint(large up) {
    justify-content: flex-start;
  }
}
