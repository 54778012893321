.article-card__header {
  display: flex;
  justify-content: space-between;

  @include breakpoint(medium) {
    margin-bottom: calc($general-block-margin / 2);
  }
}

.article-card__image-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: $general-block-margin;
  overflow: hidden;
  aspect-ratio: 1.78;

  figure {
    transition: transform 0.35s;
  }

  // &.article-card__image-wrapper--small {
  //   height: 10rem;
  // }
}

.article-card__image-copyright {
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
}

.article-card__content-wrapper {
  @include breakpoint(medium) {
    //max-width: 90%;

    .article-card--small & {
      max-width: 100%;
    }
  }
}

.article-card__wrapper--flex {
  display: flex;
  margin-bottom: $general-margin;

  .article-card__wrapper--left {
    flex: 0 0 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .article-card__wrapper--right {
    flex: 0 0 40%;
  }
}

.article-card {
  opacity: 0;
  display: block;
  position: relative;
  padding: $general-block-margin 1rem;
  overflow: hidden;

  background-color: $light-gray;
  border: 1px solid $medium-gray;
  height: 100%;

  animation: fade-in ease-in;
  @include animated();

  //&:after {
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  height: 0.15rem;
  //  width: 100%;
  //  background-color: $medium-gray;
  //  z-index: 0;
  //}
  //&:before {
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  height: 0.16rem;
  //  width: 100%;
  //  transform: translateX(-100%);
  //  background-color: $primary;
  //  transition: transform 0.25s ease-in-out;
  //  z-index: 1;
  //}

  &:hover {
    &:before {
      transform: translateX(0%);
    }

    .article-card__image-wrapper figure {
      transform: scale(1.05);
    }

    .article-card__content-title {
      color: $primary;
    }
  }
}

.article-card--small {
  &:hover {
    .article-card__image-wrapper figure {
      transform: scale(1.05);
    }

    .article-card__content-title {
      color: $primary;
    }
  }
}

.article__grid--small {
  display: flex;
  flex-wrap: wrap;
}

.article-card--full {
  flex: 1 1 100%;

  border-bottom: $general-border-black;
  padding-bottom: $general-block-margin;
  margin-bottom: $general-margin;

  .article-card__content-wrapper {
    max-width: 100%;
  }
}

.article-card--half {
  flex: 1 1 100%;

  .article-card__content-wrapper {
    max-width: 100%;
  }

  @include breakpoint(medium) {
    flex: 1 1 50%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: $general-block-margin;

    &:nth-child(even):not(:last-child),
    &:first-child:not(:last-child) {
      border-right: $general-border-black;
      padding-right: $general-margin;
    }

    &:last-child {
      padding-left: $general-margin;
    }
  }
}

.article-card__link {
  font-size: var(--interact-size);
  display: flex;
  justify-content: flex-end;
  margin-right: calc($general-block-margin / 2);
  margin-top: auto;
}

.article-card__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.article-card__lead-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: $general-block-margin;
}

.section--border,
body[class*=" theme--"] {
  .article-card--white {
    background-color: $white;
  }
}

.article-card__text-meta {
  & + hr {
    margin: 0 0 1.5rem;
  }
  td {
    vertical-align: top;
    white-space: break-spaces;
    width: 100%;
  }
  td:first-of-type {
    padding-right: 1rem;
    width: min-content;
  }
}
