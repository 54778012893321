.testimonial__wrapper {
  position: relative;
  height: 20rem;
  width: 100%;
  display: block;
  margin-bottom: $general-margin * 2;
  @include breakpoint(medium) {
    height: 65vh;
    margin-bottom: 0;
  }

  @include breakpoint(large) {
    height: 75vh;
  }
}

.testimonial__image-wrapper {
  width: 100%;
  height: 100%;
}

.testimonial__quote-wrapper {
  position: absolute;
  left: -2rem;
  bottom: -2rem;
  height: auto;
  width: 30rem;
  z-index: 20;
}

.testimonial__board {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial__quote {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 1.5rem 2rem;
  z-index: 10;

  @include breakpoint(medium) {
    padding: 4rem 3rem;
  }
}

.testimonial__link {
  margin-top: $general-margin;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include breakpoint(medium) {
    margin-top: $general-block-margin;
  }
}
