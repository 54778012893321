.responsive-image {
  margin: 0;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.responsive-image__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: "object-fit: cover";
}

.responsive-image__copyright {
  position: absolute;
  right: 0.75rem;
  bottom: 0.5rem;
}

.image {
  display: block;
  width: 100%;
}

.image--small {
  @include breakpoint(small only) {
    margin-bottom: $general-block-margin;
  }

  &:first-child {
    @include breakpoint(medium) {
      padding-right: rem-calc(15px);
    }
  }
  &:last-child {
    @include breakpoint(medium) {
      padding-left: rem-calc(15px);
    }

    @include breakpoint(small only) {
      margin-bottom: 0;
    }
  }
}

.image-masonry {
  @include breakpoint(small only) {
    figure {
      margin-bottom: $general-margin;
    }
  }

  @include breakpoint(medium) {
    figure {
      &:nth-child(even) {
        margin-top: $general-margin * 3;
      }
    }
  }
}
