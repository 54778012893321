.page-teaser__image-container {
  width: 100%;
  margin-bottom: $general-block-margin;
  height: 15rem;

  @include breakpoint(medium) {
    height: 30rem;
  }
}

.page-teaser__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-teaser__container--first {
  @include breakpoint(medium) {
    .page-teaser--right & {
      order: 2;
    }
  }
}

.page-teaser__container--second {
  .page-teaser__content {
    @include breakpoint(large) {
      padding-left: $general-margin;
    }

    .page-teaser--right & {
      @include breakpoint(large) {
        padding-right: $general-margin;
      }
      padding-left: 0;
    }
  }

  @include breakpoint(medium) {
    .page-teaser--right & {
      margin-left: 0;
      order: 1;
    }
  }
}

.page-teaser__link {
  font-size: var(--interact-size);
  display: flex;
}
